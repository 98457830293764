<template>
  <b-card v-if="visaoGeral" class="card-transaction" no-body>
    <b-card-header>
      <b-card-title>Visão Geral</b-card-title>
    </b-card-header>

    <b-card-body>
      <div
        v-for="transaction in visaoGeral"
        :key="transaction.mode"
        class="transaction-item"
      >
        <b-link :to="transaction.link">
          <b-media no-body>
            <b-media-aside>
              <b-avatar rounded size="42" :variant="transaction.avatarVariant">
                <feather-icon size="18" :icon="transaction.avatar" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ transaction.mode }}
              </h6>
              <small>{{ transaction.types }}</small>
            </b-media-body>
          </b-media>
        </b-link>
        <div
          class="font-weight-bolder"
          :class="transaction.deduction ? 'text-danger' : 'text-success'"
        >
          {{ transaction.payment }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BLink,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BLink,
  },
  data() {
    return {
      visaoGeral: [],
    };
  },
  created() {
    this.$httpReport
      .post("/executor", {
        key: "INSIGHTS_GERAIS",
        params: {},
      })
      .then((response) => {
        let insights = response.data.data;

        this.visaoGeral = [
          {
            mode: "Supervisores",
            types: "",
            avatar: "TrendingUpIcon",
            avatarVariant: "light-info",
            payment: insights["Supervisores|N"],
            deduction: false,
            link: "supervisores",
          },
          {
            mode: "Lideres em trein.",
            types: "",
            avatar: "TrendingUpIcon",
            avatarVariant: "light-info",
            payment: insights["Líderes em treinamento|N"],
            deduction: false,
          },
          {
            mode: "Pessoas cadastradas no App",
            types: "",
            avatar: "TrendingUpIcon",
            avatarVariant: "light-info",
            payment: insights["Pessoas cadastradas no App|N"],
            deduction: false,
          },
          {
            mode: "Pessoas sem PG",
            types: "",
            avatar: "TrendingUpIcon",
            avatarVariant: "light-info",
            payment: insights["Pessoas sem PGs|N"],
            deduction: false,
            link: "pessoas-sem-pg",
          },
        ];
      });
  },
};
</script>
