<template>
  <section id="dashboard-renovar">
    <b-row class="match-height">
      <b-col xl="12" md="12">
        <pgs :data="pgs" :total="totalPgs" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <!-- Transaction Card -->
      <b-col lg="4" md="6">
        <visao-geral-pgs />
      </b-col>
      <b-col lg="4" md="6">
        <frequencia-pgs />
      </b-col>
      <b-col lg="4" md="6">
        <apex-donut-chart />
      </b-col>
      <b-col lg="4" md="6">
        <!--  <visitantes/>-->
      </b-col>
      <!--/ Transaction Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import EcommerceMedal from "../ecommerce/EcommerceMedal.vue";
import Pgs from "./Pgs.vue";
import EcommerceRevenueReport from "../ecommerce/EcommerceRevenueReport.vue";
import EcommerceOrderChart from "../ecommerce/EcommerceOrderChart.vue";
import EcommerceProfitChart from "../ecommerce/EcommerceProfitChart.vue";
import EcommerceEarningsChart from "../ecommerce/EcommerceEarningsChart.vue";
import EcommerceCompanyTable from "../ecommerce/EcommerceCompanyTable.vue";
import EcommerceMeetup from "../ecommerce/EcommerceMeetup.vue";
import EcommerceBrowserStates from "../ecommerce/EcommerceBrowserStates.vue";
import EcommerceGoalOverview from "../ecommerce/EcommerceGoalOverview.vue";
import VisaoGeralPgs from "./VisaoGeralPgs.vue";
import FrequenciaPgs from "./FrequenciaPgs.vue";
import ApexDonutChart from "./ApexDonutChart.vue";
import Visitantes from "./Visitantes.vue";

export default {
  components: {
    BRow,
    BCol,
    EcommerceMedal,
    Pgs,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    VisaoGeralPgs,
    FrequenciaPgs,
    ApexDonutChart,
    Visitantes,
  },
  data() {
    return {
      data: {},
      pgs: [],
      totalPgs: 0,
    };
  },
  created() {
    // data
    this.$http.get("/ecommerce/data").then((response) => {
      this.data = response.data;

      // ? Your API will return name of logged in user or you might just directly get name of logged in user
      // ? This is just for demo purpose
      const userData = getUserData();
      this.data.congratulations.name =
        userData.fullName.split(" ")[0] || userData.username;
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
