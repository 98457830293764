<template>
  <b-card v-if="frequenciaPgs" class="card-transaction" no-body>
    <b-card-header>
      <b-card-title>Frequência PGs</b-card-title>
      <!--
      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item @click="buscarDados(7)" :value="7" v-model="periodo">
          Ultima Semana
        </b-dropdown-item>
      </b-dropdown>-->
    </b-card-header>

    <b-card-body>
      <b-row>
        <b-col cols="12" sm="12" class="mb-1">
          <date-picker
            v-model="dtFiltro"
            range
            format="DD/MM/YYYY"
            lang="pt-br"
          ></date-picker>
        </b-col>
      </b-row>
      <div
        v-for="transaction in frequenciaPgs"
        :key="transaction.mode"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" :variant="transaction.avatarVariant">
              <feather-icon size="18" :icon="transaction.avatar" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ transaction.mode }}
            </h6>
            <small>{{ transaction.types }}</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :class="transaction.deduction ? 'text-danger' : 'text-success'"
        >
          {{ transaction.payment }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
} from "bootstrap-vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    DatePicker,
    BRow,
    BCol,
  },
  data() {
    return {
      frequenciaPgs: [],
      periodo: 7,
      dtIni: null,
      dtFim: null,
      dtFiltro: [new Date(), new Date()],
    };
  },
  watch: {
    dtFiltro(val) {
      this.buscarDados();
    },
    periodo(val) {
      this.buscarDados();
    },
  },
  created() {
    let dtIni = this.getLastSunday(new Date());
    let dtFim = this.getLastSunday(new Date());
    dtFim.setDate(dtFim.getDate() + 7);
    this.dtFiltro = [dtIni, dtFim];
    this.buscarDados();
  },
  methods: {
    getLastSunday(d) {
      var t = new Date(d);
      t.setDate(t.getDate() - t.getDay());
      return t;
    },
    buscarDados() {
      this.frequenciaPgs = [];
      //let dtAtual = new Date();
      this.dtIni = this.dtFiltro[0];
      this.dtFim = this.dtFiltro[1];

      // add a day
      //this.dtIni.setDate(this.dtIni.getDate() - dtAtual.getDay());

      this.$httpReport
        .post("/executor", {
          key: "FREQUENCIA_PGS",
          params: {
            DT_INI: this.dtIni.toLocaleDateString("en-CA"),
            DT_FIM: this.dtFim.toLocaleDateString("en-CA"),
          },
        })
        .then((response) => {
          let insights = response.data.data;

          this.frequenciaPgs = [
            {
              mode: "Total de Pessoas",
              types: "",
              avatar: "TrendingUpIcon",
              avatarVariant: "light-info",
              payment: insights["Total de Pessoas"],
              deduction: false,
            },
            {
              mode: "Rede de Homens",
              types: "",
              avatar: "TrendingUpIcon",
              avatarVariant: "light-info",
              payment: insights["Rede de Homens"],
              deduction: false,
            },
            {
              mode: "Rede de Mulheres",
              types: "",
              avatar: "TrendingUpIcon",
              avatarVariant: "light-info",
              payment: insights["Rede de Mulheres"],
              deduction: false,
            },
            {
              mode: "Rede de Jovens",
              types: "",
              avatar: "TrendingUpIcon",
              avatarVariant: "light-info",
              payment: insights["Rede de Jovens"],
              deduction: false,
            },
            {
              mode: "Rede de Casais",
              types: "",
              avatar: "TrendingUpIcon",
              avatarVariant: "light-info",
              payment: insights["Rede de Casais"],
              deduction: false,
            },
            {
              mode: "Crianças",
              types: "",
              avatar: "TrendingUpIcon",
              avatarVariant: "light-info",
              payment: insights["Crianças"],
              deduction: false,
            },
          ];
        });
    },
  },
};
</script>
