<template>
  <b-card>
    <b-card-title class="mb-1"> Redes </b-card-title>
    <b-card-sub-title class="mb-2"> Numero das redes </b-card-sub-title>

    <vue-apex-charts
      ref="chart"
      type="donut"
      height="350"
      :options="donutChart.chartOptions"
      :series="donutChart.series"
    />
  </b-card>
</template>

<script>
import { BCard, BCardTitle, BCardSubTitle } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      dados: {},
      labels: {},
      donutChart: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          colors: ["#ffe700", "#00d4bd", "#826bf8", "#2b9bf4", "#FFA1A1"],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)} Pgs`;
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "1.5rem",
                    label: "Total",
                    formatter: function (w) {
                      return (
                        w.globals.seriesTotals.reduce((a, b) => {
                          return a + b;
                        }, 0) + " PGs"
                      );
                    },
                  },
                },
              },
            },
          },
          labels: [],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    };
  },
  created() {
    this.$httpReport
      .post("/executor", {
        key: "QUANTIDADE_PGS_ATIVOS_POR_REDE",
        params: {},
      })
      .then((response) => {
        let insights = response.data.data;

        //
        this.labels = Object.keys(insights);
        this.dados = Object.values(insights);

        this.$refs.chart.updateOptions({
          labels: this.getLabels(),
          series: this.getSeries(),
        });
      });
  },
  methods: {
    getLabels() {
      return this.labels.map(function (label) {
        return label.replace("Rede de ", "");
      });
    },
    getSeries() {
      return this.dados.map(function (label) {
        return parseInt(label);
      });
    },
  },
};
</script>
